const SchoolCareBodyMiddle = () => {

  return (
    <section className="py-2">

      <div className="border-b border-gray-600"/>

      <div className='px-2 py-4'>
        <h1 className='text-7xl md:text-9xl lg:text-13xl font-extrabold underline' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
          Your Partnership <span className="text-orange-500">Benefits</span>
        </h1>

        {/* <p className='mb-4 text-lg md:text-xl lg:text-3xl'>
          As a <span className='font-semibold text-orange-500'>LearnNova School Support Business Owner</span>, you gain access to industry-leading tools, training, and resources designed to help you build a thriving and profitable business. Here’s what’s included:
        </p> */}

        <ul className="list-decimal mx-7 text-lg md:text-xl lg:text-3xl font-bold text-orange-500 space-y-4">
          <li>
            Seamless Onboarding Program & Continuous Learning Initiative
            <ul className="mx-6 text-lg md:text-xl lg:text-3xl font-normal text-white space-y-3" style={{listStyleType: 'circle'}}>
              <li>
                <span className="font-semibold">5-Day Intensive Virtual Training</span> (6 hours per day) to equip you with the skills and strategies needed to get started quickly.
              </li>
              <li>
                <span className="font-semibold">Continuous Sales & Business Growth Training Sessions</span> to keep you ahead in the education support industry with cutting-edge insights.
              </li>
              <li>
                <span className="font-semibold">Exclusive Private School Growth Mentorship & Training Program</span> – Gain the expertise to mentor and train private schools for sustained success. <span className="font-semibold">(Free upon reaching 55 customers.)</span>
              </li>
              <li>
                <span className="font-semibold">Entrepreneurial Skills Development for Business Start-Up</span> – Gain essential knowledge to successfully launch and scale a business. <span className="font-semibold">(Free upon reaching 75 customers.)</span>
              </li>
              <li>
                Critical Skills and Proven Strategies for Building and Running a Successful Business Consulting Practice. <span className="font-semibold">(Free upon achieving 100 customers)</span>
              </li>
            </ul>
          </li>

          <li>
            Expert Training & Mentorship Opportunities:
            {/* <p className="text-lg md:text-xl lg:text-3xl mb-3 text-white font-normal">A <span className="font-semibold">multi-platform learning app</span> (Android, iOS, and desktop) for students to learn at home during weekends, holidays, and after school:</p> */}
            <ul className="mx-6 text-lg md:text-xl lg:text-3xl font-normal text-white space-y-3" style={{listStyleType: 'circle'}}>
              <li>
                Leverage our <span className="font-semibold">School Growth Training Platform</span> to host paid training sessions for school owners and educators. Set your own pricing and retain 100% of the revenue.
              </li>
              <li>
                Enroll your clients in our <span className="font-semibold">Regular School Growth Virtual Mentorship & Coaching Program</span>, led by industry experts. You have full control over pricing and keep all earnings.
              </li>
              <li>
                Participate in <span className="font-semibold">Ongoing Business Growth Sessions</span> to stay ahead in the education sector with the latest strategies and insights.
              </li>
              <li>
                Get <span className="font-semibold">free copies of our Private School Growth Guidebook</span> to share with school owners, providing them with valuable business growth strategies.
              </li>
            </ul>
          </li>

          <li>
            Get Industry-Leading Educational and Private School Growth Solutions:
            <p className="text-lg md:text-xl lg:text-3xl mb-3 text-white font-normal">Enhance learning with our <span className="font-semibold">versatile, multi-platform educational system</span>, designed for both classroom and home learning. Our system provides <span className="font-semibold">comprehensive learning content</span>, engaging exercises, and structured assessments to support learners success. Available as a <span className="font-semibold">desktop application</span> for schools and on <span className="font-semibold">Android, iOS, and desktop</span> for home learning.</p>

            <ol className="mx-6 text-lg md:text-xl lg:text-3xl font-normal text-white font-semibold space-y-3" style={{listStyleType: 'upper-alpha'}}>
              <li>
                Key Features:
                <ul className="list-none mx-6 text-lg md:text-xl lg:text-3xl font-normal text-white space-y-2">
                  {/* <p>A <span className="font-semibold">comprehensive desktop-based platform</span> with:</p> */}
                  <li className="font-semibold"><span className="mr-1">✅</span>Mathematics for Senior Secondary School – 2,400 lessons + 8,000+ exam questions.</li>
                  <li className="font-semibold"><span className="mr-1">✅</span>Diction and Phonetics (KG to Grade 6) – 540 lessons + 500+ exercises.</li>
                  <li><span className="mr-1">✅</span><span className="font-semibold">E-Library for Elementary & Secondary Schools</span> – <span className="font-semibold">650–950 books</span>, fostering a strong reading culture.</li>
                  <li><span className="mr-1">✅</span><span className="font-semibold">Creative & Vocational Learning</span> – Engaging courses that enhance creativity and practical skills for all age groups.</li>
                  {/* <li><span className="mr-1">✅</span><span className="font-semibold">Full Resale Rights</span> – Pay a discounted usage fee of <span className="font-semibold">₦95,875</span> per unit per term to LearnNova, then set your own resale price or offer it at LearnNova’s standard rate of <span className="font-semibold">₦125,000</span> per unit per term.</li> */}
                </ul>
              </li>

              <li>
                Pricing & Enrollment Packages:
                <ul className="list-none mx-6 text-lg md:text-xl lg:text-3xl font-normal text-white space-y-2">
                  <li><span className="mr-1">✅</span><span className="font-semibold text-orange-500">Bronze Package: </span><span className="font-semibold">Classroom Desktop Application + 35 learners</span> enrolled in the Home Learning App – <span className="font-semibold">₦79,500 per term</span></li>
                  <li><span className="mr-1">✅</span><span className="font-semibold text-orange-500">Silver Package: </span><span className="font-semibold">Classroom Desktop Application + 50 learners</span> enrolled in the Home Learning App – <span className="font-semibold">₦99,500 per term</span></li>
                  <li><span className="mr-1">✅</span><span className="font-semibold text-orange-500">Gold Package: </span><span className="font-semibold">Classroom Desktop Application + 80 learners</span> enrolled in the Home Learning App – <span className="font-semibold">₦129,500 per term</span></li>
                </ul>
              </li>

              <p className="text-lg md:text-xl lg:text-3xl my-3 text-white font-normal"><span className="font-semibold">Full Resale Rights:</span> Purchase at LearnNova’s discounted rate (as stated above) and resell to schools at your preferred price or LearnNova’s recommended standard rate that guarantee a minimum of <span className="font-semibold">66%</span> profit on purchase price.</p>
            </ol>

            <p className="text-lg md:text-xl lg:text-3xl mt-3 text-white font-normal">Give schools access to <span className="font-semibold">quality learning resources</span> and <span className="font-semibold">transform the educational experience</span> in your community today!</p>
          </li>    

          <li>
            Brand & Ongoing Support:
            <ul className="mx-6 text-lg md:text-xl lg:text-3xl font-normal text-white" style={{listStyleType: 'circle'}}>
              <li>
                Leverage LearnNova’s 15+ years of industry leadership, plus marketing and networking support to connect with private school associations.
              </li>
            </ul>
          </li>
        </ul>

        <div className='my-4 text-lg md:text-xl lg:text-3xl'>
          <p className="font-semibold text-orange-500">Invest in Your Success</p>
          <p>Start your journey as a <span className="font-semibold">LearnNova School Support Partner</span> for just <span className="font-semibold">₦75,000</span> and unlock all these benefits instantly.</p>
        </div>
      </div>

      {/* <div className='px-2 py-4'>
        <h1 className='text-7xl md:text-9xl lg:text-13xl font-extrabold underline' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
          Start-Up <span className="text-orange-500">Investment</span> & <span className="text-orange-500">Maintenance:</span>
        </h1>

        <table className="mt-2 table-auto border-collapse border border-gray-400 w-full">
          <tbody className="text-lg md:text-xl lg:text-3xl">
              <tr className="bg-gray-100 text-black">
                <td className="border border-gray-400 px-4 py-2">Registration Fee:</td>
                <td className="border border-gray-400 px-4 py-2 font-semibold">₦75,000</td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2">License Renewal Requirement:</td>
                <td className="border border-gray-400 px-4 py-2">A minimum of <span className="font-semibold">10 active</span> customers per year</td>
              </tr>
              <tr className="bg-gray-100 text-black">
                <td className="border border-gray-400 px-4 py-2">Working Capital:</td>
                <td className="border border-gray-400 px-4 py-2">Sufficient liquid assets to cover <span className="font-semibold text-orange-500">4 months</span> of operational costs.</td>
              </tr>
          </tbody>
        </table>

        <a className='text-lg md:text-xl lg:text-3xl font-semibold text-orange-500 hover:underline hover:text-white mt-2' href="#getintouch2">
          Enrol Now
        </a>
      </div> */}

      <div className="border-b border-gray-600 mt-4"/>
    </section>
  );
};

export default SchoolCareBodyMiddle;

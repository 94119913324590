// import availableTerritories from "./availableTerritories";

const SchoolCareBodyTop = () => {

  return (
    <section className="py-2">
        <div className='mx-2 mb-7 p-2 border rounded-md border-gray-600 flex flex-col' style={{background: '#25242a'}}>
            <h1 className='mb-3 text-7xl md:text-9xl lg:text-13xl font-extrabold' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
                Why Partner with <span className="text-orange-500">LearnNova</span>?
            </h1>

            <p className='mb-2 text-lg md:text-xl lg:text-3xl'>
              <span className="mr-1">✅</span><span className="font-semibold text-orange-500">Exclusive Access to Premium Educational Resources</span> – Get top-tier learning tools and services to support schools and pupils/students in your community.
            </p>

            <p className='mb-2 text-lg md:text-xl lg:text-3xl'>
              <span className="mr-1">✅</span><span className="font-semibold text-orange-500">Lucrative Revenue Opportunities</span> – Set your own prices, sell to schools, and maximize your earnings while making a meaningful impact.
            </p>

            <p className='mb-2 text-lg md:text-xl lg:text-3xl'>
              <span className="mr-1">✅</span><span className="font-semibold text-orange-500">Full Resale Rights</span> – Buy at LearnNova's discounted price and resell to unlimited schools at any rate you choose.
            </p>

            {/* <p className='mb-2 text-lg md:text-xl lg:text-3xl'>
              <span className="mr-1">✅</span><span className="font-semibold text-orange-500">Embrace Growth Opportunities</span> – Work with the School Support Network team to identify and seize expansion opportunities in your market.
            </p>

            <p className='mb-2 text-lg md:text-xl lg:text-3xl'>
              <span className="mr-1">✅</span><span className="font-bold text-orange-500">Market Your Business</span> – Use our marketing support to promote your services and attract customers.
            </p>

            <p className='mb-2 text-lg md:text-xl lg:text-3xl'>
              <span className="mr-1">✅</span><span className="font-bold text-orange-500">Follow the System</span> – Success is built on consistency. Our <span className="font-bold">15+ year proven system</span> ensures you don’t have to start from scratch.
            </p> */}
        </div>
        {/* <div className="mx-2 p-2 border rounded-md border-gray-600 flex flex-col items-center text-center" style={{background: '#25242a'}}>
            <h1 className='text-7xl md:text-9xl lg:text-13xl font-extrabold uppercase' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
                TERRITORIES, WHAT YOU GET & INVESTMENT
            </h1>
            <p className='mb-4 text-lg md:text-xl lg:text-3xl'>
                LearnNova School Support Business is expanding across multiple markets.
            </p>

            <a className='text-lg md:text-xl lg:text-3xl font-extrabold capitalize text-orange-500 hover:underline hover:text-white' href='#territories' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
                Check out what territories in Nigeria are open for investment.
            </a>
        </div> */}

        {/* <div className="px-2 py-10 border flex flex-col items-center text-center">
            <h1 className='text-7xl md:text-9xl lg:text-13xl font-extrabold uppercase' style={{fontFamily: 'Big Shoulders Display, sans-serif'}} id="schoolbusinessopportunity">
                IS A SCHOOL SUPPORT BUSINESS RIGHT FOR YOU?
            </h1>
            <p className='mb-4 text-lg md:text-xl lg:text-3xl'>
                As a <a className='font-extrabold text-orange-500 hover:underline hover:text-white' href="#quiz">LearnNova School Support Business Owner</a>, you’ll have the independence to run your own business while leveraging the credibility and resources of a nationally recognized brand in the private school sector. Enjoy the flexibility of entrepreneurship with the support you need to succeed.
            </p>

            <a className='text-lg md:text-xl lg:text-3xl font-extrabold capitalize text-orange-500 hover:underline hover:text-white' href='#quiz' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
                Find Out More
            </a>
        </div> */}

        {/* <div className="border-b border-gray-600"/> */}

        {/* <div className='px-2 py-4'>
            <h1 className='text-7xl md:text-9xl lg:text-13xl font-extrabold text-center' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
                The LearnNova School Support Business <span className='text-orange-500'>Advantage</span>
            </h1>
            <p className='mb-4 text-lg md:text-xl lg:text-3xl'>
                What makes us stand out? We excel across the industry, providing our partners with a powerful competitive edge.
            </p>

            <a className='text-lg md:text-xl lg:text-3xl font-extrabold text-orange-500 hover:underline hover:text-white' href='#schoolsupportadvantage' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
                [See the Advantages]
            </a>
        </div> */}

        {/* <div className="px-2 py-10 border flex flex-col items-center text-center">
            <h1 className='text-7xl md:text-9xl lg:text-13xl font-extrabold' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
                What Defines a Successful School Support Business Operator?
            </h1>
            <p className='mb-4 text-lg md:text-xl lg:text-3xl'>
                Our operators come from diverse backgrounds but share a common drive - proactive leadership, adaptability, and a commitment to leveraging a proven business system to create lasting impact.
            </p>

            <a className='text-lg md:text-xl lg:text-3xl font-extrabold capitalize text-orange-500 hover:underline hover:text-white' href='#greatschoolsupportoperator' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
                [Learn More]
            </a>
        </div> */}

        {/* <div className='px-2 py-4'>
            <h1 className='text-7xl md:text-9xl lg:text-13xl font-extrabold text-center text-orange-500' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
                Customer Stories
            </h1>
            <p className='mb-4 text-lg md:text-xl lg:text-3xl'>
                Discover why investors chose School Support Partners, the nation’s leading brand in private school growth.
            </p>

            <a className='text-lg md:text-xl lg:text-3xl font-extrabold text-orange-500 hover:underline hover:text-white' href='#a' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
                [Read Their Stories]
            </a>
        </div> */}

        {/* <div className="border-b border-gray-600"/> */}

        {/* <div className='px-2 py-4'>
            <p className='mb-4 text-3xl md:text-5xl lg:text-7xl'>
                Explore the Financial Benefits, Personal Rewards, and Geographic Requirements <span className='font-semibold text-orange-500 hover:text-white'>Below:</span>
            </p>

            <table className="table-auto border-collapse border border-gray-400 w-full">
                <tbody className="text-lg md:text-xl lg:text-3xl">
                    <tr>
                        <td className="border border-gray-400 px-4 py-2">Typical Subscription Fee per School (Per Term):</td>
                        <td className="border border-gray-400 px-4 py-2 font-semibold">₦125,000</td>
                    </tr>
                    <tr className="bg-gray-100">
                        <td className="text-black border border-gray-400 px-4 py-2">Average Financial Gain Per Customer for a <span className="font-semibold text-orange-500">School Support Business Owner</span>:</td>
                        <td className="text-black border border-gray-400 px-4 py-2"><span className="text-orange-500 font-semibold">23.3%</span> of the termly subscription fee (<span className="font-semibold">₦400k</span> income per month on <span className="font-semibold">55</span> customers).</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-400 px-4 py-2">One-Week Setup Training, Continuous Capability Development Training, & Marketing E-Flier:</td>
                        <td className="border border-gray-400 px-4 py-2 font-semibold underline text-orange-500 text-center">FREE</td>
                    </tr>
                    <tr className="bg-gray-100">
                        <td className="text-black border border-gray-400 px-4 py-2">Available Territories:</td>
                        <td className="text-black border border-gray-400 px-4 py-2 font-semibold"><a className="text-orange-500 hover:underline hover:text-black" href="#territories">(See list below)</a> – Applicants must reside in an open geographic territory.</td>
                    </tr>
                </tbody>
            </table>
        </div> */}

        {/* <div className='my-10 mx-2 p-2 border rounded-md border-gray-600' style={{background: '#25242a'}} id="territories">
            <h1 className='text-7xl md:text-9xl lg:text-13xl uppercase font-extrabold text-orange-500 underline' style={{fontFamily: 'Big Shoulders Display, sans-serif'}}>
                AVAILABLE TERRITORIES
            </h1>

            <ul className="list-decimal mx-6 text-lg md:text-xl lg:text-3xl uppercase">
                {
                    availableTerritories.map((territory)=> {
                        return (
                            <li key={territory.value}>{territory.name}</li>
                        );
                        
                    })
                }
            </ul>
        </div> */}

    </section>
  );
};

export default SchoolCareBodyTop;

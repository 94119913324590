const availableTerritories = [
    {
        name: 'LAGOS',
        value: 'lagos'
    },
    {
        name: 'ABUJA',
        value: 'abuja'
    },
    {
        name: 'PORTHARCOURT, RIVER STATE',
        value: 'portharcourt'
    },
    {
        name: 'IBADAN, OYO STATE',
        value: 'ibadan'
    },
    {
        name: 'ABEOKUTA, OGUN STATE',
        value: 'abeokuta'
    },
    {
        name: 'OSOGBO, OSUN STATE',
        value: 'osogbo'
    },
    {
        name: 'AKURE, ONDO STATE',
        value: 'akure'
    },
    {
        name: 'ADO, EKITI STATE',
        value: 'ado'
    },
    {
        name: 'BENNIN, EDO STATE',
        value: 'bennin'
    },
    {
        name: 'WARRI, DELTA STATE',
        value: 'warri'
    },
    {
        name: 'OWWERI, IMO STATE',
        value: 'owweri'
    },
    {
        name: 'KADUNA, NORTH WEST',
        value: 'kaduna'
    },
    {
        name: 'KANO, NORTH WEST',
        value: 'kano'
    },
    {
        name: 'JOS, PLATEAU STATE',
        value: 'jos'
    },
    {
        name: 'ILORIN, NORTH CENTRAL',
        value: 'ilorin'
    },
    {
        name: 'ENUGU, SOUTH EAST',
        value: 'enugu'
    },
    {
        name: 'AKWA IBOM STATE',
        value: 'akwa'
    },
    {
        name: 'CROSS RIVER STATE',
        value: 'cross-river'
    },
];


export default availableTerritories;

import React from 'react';
import ReferralProgram from 'layouts/MentorNigeriaSections/ReferralProgram';
import FooterSection from '../layouts/MentorNigeriaSections/footerSection';

const ReferralPage = () => {
  return (
    <div className='main-container'>
        <ReferralProgram />
        <FooterSection />
    </div>
  )
}

export default ReferralPage